'use strict';

import 'adapter';
import AgentSocket from './agentsocket';
import store from '../../redux/store/configureStore';
import {
	updateVideoInProgress,
	updateAgentUnAvailOnVidCall,
	showVideoCallFrame,
	handleAgentRecordVid,
	handleAgentScreenShare,
	handleAgentScreenShareOffer,
	handleClientScreenShare,
	handleClientScreenShareOffer,
	handleClientRejectVidCall,
	handleClientOfferCoBrowsing,
	toggleVidChatFullScreen,
	handleClientSelectedDisplay,
	handleAgentMouseAccess,
	handleAgentKeyboardAccess,
	updateVideoExtractingStatus
} from '../../redux/actions/errand';
import {
	DISMISS_BUTTONS,
	customConfirm
} from '../../redux/actions/async/hmf';
import { handleSavingRecordedVidChat } from '../../redux/actions/async/errand';
import { SelfieSegmentation } from '@mediapipe/selfie_segmentation';


const offerOptions = {
	offerToReceiveAudio: 1,
	offerToReceiveVideo: 1,
	iceRestart: true
};

const coBrowseOfferOptions = {
	offerToReceiveAudio: 1,
	offerToReceiveVideo: 1,
	iceRestart: true
};

const mediaStreamConstraints = {
	video: true,
	audio: true
};

var sdpConstraints = {'mandatory': {'OfferToReceiveAudio':true, 'OfferToReceiveVideo':true }};

//we can control here the limit on what should be opt to share
//for example, video { mandatory: { chromeMediaSource: 'screen' }} is opt
//only tabs within chrome
const displayMediaOptions = {
	video: {
		cursor: "always"
	},
	audio: false
};

let pc1, pcDisplay, sendChannel, receiveChannel;
let localStream, remoteStream, displayMediaStream, remoteDisplayStream;
let agentCalling = false, localVidLoading = true;
let localVideo;

var collectedICEs = [] ,collectedDisplayICEs = [];

var blurActivated = false;
var presetBgBlur = false;
var presetBg = false;
var presetBgImg = "";

function getName() {
	return "Agent PC";
}

var clientSnapShots = "";

function getRTCConf() {
	let turnHOSTURL = "", turnUsername = "", turnToken = "";
	if(features["turn.host-url"]) {
		turnHOSTURL = "turn:"+features["turn.host-url"];
	}
	if(features["turn.username"]) {
		turnUsername = features["turn.username"];
	}
	if(features["turn.password"]) {
		turnToken = features["turn.password"];
	}
	const rtcConf = {
		"iceServers":
			[{
				"urls":[turnHOSTURL],"username":turnUsername,"credential":turnToken
			}],
		"iceTransportPolicy":"all","iceCandidatePoolSize":"0"
	};
	return rtcConf;
}

async function handleRemoteStreamAdded(e) {
	remoteStream = e.streams[0];
	attachMediaStream("remote", remoteStream);
}

function handleDataChannel(e) {
	receiveChannel = e.channel;
}

const createPeerConnection = (sessionId) => {
	const RTCCONFIG = getRTCConf();
	pc1 = new RTCPeerConnection(RTCCONFIG);
	if(!sendChannel || (sendChannel && sendChannel.readyState === "closed")) {
		sendChannel = pc1.createDataChannel("AgentChannel");
	}

	pc1.addEventListener('icecandidate', e => handleICECandidate(pc1, sessionId, e));
	pc1.addEventListener("track", (e) => handleRemoteStreamAdded(e));

	pc1.addEventListener('iceconnectionstatechange', e => onIceStateChange(pc1, sessionId, e));
	pc1.addEventListener('icegatheringstatechange', e => onICEGatheringChange(pc1, e));

	pc1.addEventListener('datachannel', e => handleDataChannel(e));

	if(receiveChannel) {
		receiveChannel.onmessage = function (e) {
			controlRemoteEvents(e);
		};
	}

	if(sendChannel) {
		sendChannel.onmessage = function (e) {
			controlRemoteEvents(e);
		};
		sendChannel.onopen = function () {
			console.log("agent data send channel opened");
		};
		sendChannel.onclose = function () {
			console.log("agemt data send channel closed");
		};
	}
	return pc1;
};

function IsJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

async function handleRemoteDisplayStreamAdded(e) {
	remoteDisplayStream = e.streams[0];
	attachMediaStream("remote-display", remoteDisplayStream);
}

function handleDisplayRemoteDataChannel(e) {
	receiveChannel = e.channel;
	if(receiveChannel) {
		receiveChannel.onmessage = function (e) {
			controlRemoteEvents(e);
		}
	}
}

const createDisplayConnection = (sessionId) => {
	const RTCCONFIG = getRTCConf();
	pcDisplay = new RTCPeerConnection(RTCCONFIG);
	pcDisplay.addEventListener('icecandidate', e => handleICECandidate(pcDisplay, sessionId, e));
	pcDisplay.addEventListener('track', (e) => handleRemoteDisplayStreamAdded(e));

	//data channel
	if(!sendChannel || (sendChannel && sendChannel.readyState === "closed")) {
		sendChannel = pcDisplay.createDataChannel("AgentDisplayChannel");
	}

	pcDisplay.addEventListener('datachannel', handleDisplayRemoteDataChannel);
	pcDisplay.addEventListener('iceconnectionstatechange', e => onIceStateChange(pcDisplay, sessionId, e));
	pcDisplay.addEventListener('icegatheringstatechange', e => onICEGatheringChange(pcDisplay, e));

	var negotiating = false;// Chrome workaround
	pcDisplay.onnegotiationneeded = (event) => {
		var agentSharingScreen = store.getState().chat.socket.agentSharingScreen;
		var agentOfferInProgress = store.getState().chat.socket.agentScreenSharingOfferInProgress;
		if((!agentSharingScreen && agentOfferInProgress)) {
			negotiating = true;
			if(negotiating) {
				pcDisplay.createOffer({iceRestart: true}).then(d => pcDisplay.setLocalDescription(d))
				.then(() => sendMessage("offer", pcDisplay.localDescription, sessionId, false))
				.catch((e) => {
					console.log("Error negotiating", e);
				});
			}
		}
	};

	if(sendChannel) {
		sendChannel.onmessage = function (e) {
			controlRemoteEvents(e);
		};
		sendChannel.onopen = function () {
			console.log("Agent display data send channel opened");
		};
		sendChannel.onclose = function() {
			console.log("Agent display data send channel closed");
		}
	}

	return pcDisplay;
};

function controlRemoteEvents(e) {
	var msg = e.data;
	if(IsJsonString(msg)) {
		msg = JSON.parse(msg);
	} else {
		msg = msg;
	}
	if(msg.type === "client-snapshots") {
		/* var img = document.getElementById("coBrowseFrame");
		clientSnapShots += msg.img;
		if(msg = "\n") {
			console.log("finish");
			img.src=clientSnapShots;
		} */
	} else if(msg.type === "client-resize") {
		console.log("client resizes window ", msg);
	} else if(msg.type === "toggle-keyboard-access") {
		console.log("toggle keyboard access ", msg);
		store.dispatch(handleAgentKeyboardAccess(msg.value));
	} else if(msg.type === "toggle-mouse-access") {
		store.dispatch(handleAgentMouseAccess(msg.value));
	} else if(msg.type === "cancel-co-browsing") {
		handleClientRejectScreenShare(msg.sessionId, true);
	} else {
		//console.log("Info: other event", msg);
	}
}

//Answering video-offer from client
export function createAnswerForClient(msg, sessionId) {
	if(!pc1 || pc1.signalingState == "closed") {
		pc1 = createPeerConnection(sessionId);
	}

	pc1.setRemoteDescription(msg.sdp.sdp)
	.then(function () {
		navigator.mediaDevices.getUserMedia(mediaStreamConstraints)
		.then(function(stream) {
			localStream = stream;
			localStream.getTracks().forEach(track => {
				//This is to add local stream to local conn track
				//so that remote conn can capture this as remote stream
				pc1.addTrack(track, localStream);
			});
			attachMediaStream("local", stream);
		})
		.then(function() {
			pc1.createAnswer(sdpConstraints).then(function(answer) {
				setLocalAndSendMessage(answer, sessionId, false, true)
			})
			.catch(errorCallBack);

			//Adding those ICE after remoteDescription completed
			if(collectedICEs && collectedICEs.length > 0) {
				collectedICEs.forEach(function( cand, i ) {
					if(cand) {
						var candidate = new RTCIceCandidate(cand);
						pc1.addIceCandidate(candidate)
						.then(() => {
							//console.log("Done Adding ICEs into local conn");
						})
						.catch((e) => {
							reportError(e);
						});
					}
				});
			}
		})
	})
};

//Answering screen-share offer from client
export async function handleReceiveClientDisplay(msg, sessionId) {
	if(!pcDisplay || pcDisplay.signalingState == "closed") {
		pcDisplay = createDisplayConnection(sessionId);
	}
	if(pcDisplay) {
		pcDisplay.setRemoteDescription(msg.sdp)
		.then(function() {
			pcDisplay.createAnswer(sdpConstraints).then(function(answer) {
				setLocalAndSendMessage(answer, sessionId, false , false, msg.coBrowse);
				store.dispatch(handleClientSelectedDisplay(msg.selectedDisplay));
			})
			.catch(errorCallBack);
			if(collectedDisplayICEs && collectedDisplayICEs.length > 0) {
				collectedDisplayICEs.forEach(function( cand, i ) {
					if(cand) {
						var candidate = new RTCIceCandidate(cand);
						//FIXME: In certain scenarios here failed, cant recall when
						pcDisplay.addIceCandidate(candidate)
						.then(() => {
							if (process.env.NODE_ENV !== 'production') {
								//console.log("Done Adding ICEs into local conn");
							}
						})
						.catch((e) => {
							reportError(e);
						});
					}
				});
			}
		})
	} else {
		console.log("Error: No peer connection");
	}
}

async function setLocalAndSendMessage(sessionDescription, sessionId, agentInitiated, init, coBrowse) {
	if (sessionDescription.type == "answer") {
		if(init) {
			await pc1.setLocalDescription(sessionDescription);
		} else {
			await pcDisplay.setLocalDescription(sessionDescription);
		}
		sendMessage("answer",sessionDescription, sessionId, init, "", coBrowse);
		if(!init) {
			store.dispatch(handleClientScreenShareOffer(false));
		}
	}
};

var vidHeight, vidWidth;
export async function startWebCamAction(sessionId) {
	console.log("Starting webcam");
	localVideo = document.getElementById('CentionChatVideoFrameLocalAgent');
	showVideoCallWrapper();
	console.log('Requesting local stream');
	const stream = await navigator.mediaDevices.getUserMedia(mediaStreamConstraints);
	console.log('Received local stream', stream);
	localVideo.srcObject = stream;
	localStream = stream;

	//TODO: Video loader
	var playPromise = localVideo.play();
	if (playPromise !== undefined) {
		playPromise.then(_ => {
			localVidLoading = false;
		})
		.catch(error => {
			console.log("Error on playing video:", error);
		});
	}

	localVideo.addEventListener('loadedmetadata', onLocalVideoLoaded);

	store.dispatch(handleClientRejectVidCall(false));
}

function onLocalVideoLoaded(e) {
	vidHeight = e.target.videoHeight;
	vidWidth = e.target.videoWidth;
}

function showVideoCallWrapper() {
	document.getElementById('localAgentVideoWrapper').style.display = "flex";
	document.getElementById('remoteAgentVideoWrapper').style.display = "flex";
}

export async function call(sessionId) {
	agentCalling = true;
	if(localStream) {
		const videoTracks = localStream.getVideoTracks();
		const audioTracks = localStream.getAudioTracks();
		if (videoTracks.length > 0) {
			console.log(`Using video device: ${videoTracks[0].label}`);
		}
		if (audioTracks.length > 0) {
			console.log(`Using audio device: ${audioTracks[0].label}`);
		}
	}

	if(!pc1 || pc1.signalingState === "closed") {
		pc1 = createPeerConnection(sessionId);
	} else {
		if(pc1.signalingState == "closed") {
			//create new one
			pc1 = createPeerConnection(sessionId);
		}
	}

	if(pc1) {
		localStream.getTracks().forEach(track => pc1.addTrack(track, localStream));

		//reset ICEs to be a fresh one
		var clientSharingScreen = store.getState().chat.socket.clientSharingScreen;
		var agentSharingScreen = store.getState().chat.socket.agentSharingScreen;
		var coBrowsingMode = store.getState().chat.socket.showCoBrowseFrame;
		if(agentSharingScreen || clientSharingScreen || coBrowsingMode) {
			collectedICEs = [];
		}
		createCallOffer(pc1, sessionId);
	}
}

export async function createCallOffer(pc, sessionId) {
	let offerOpt = offerOptions, offerType = "offer";
	try {
		if (process.env.NODE_ENV !== 'production') {
			console.log(getName(), ' createOffer start');
		}
		const offer = await pc.createOffer(offerOpt);
		await onCreateOfferSuccess(offer, sessionId, offerType);
	} catch (e) {
		onCreateSessionDescriptionError(e);
	}
}

export function attachMediaStream(type, stream) {
	localVideo = document.getElementById('CentionChatVideoFrameLocalAgent');
	const remoteVideo = document.getElementById('CentionChatVideoFrameRemoteAgent');
	const remoteDisplayVid = document.getElementById('client-display-view');

	console.log("DEBUG: Adding stream", type, ":", stream);

	let vid;
	if(type === "local") {
		document.getElementById('localAgentVideoWrapper').style.display = "flex";
		vid = localVideo;
	}else if(type === "remote") {
		if(stream) {
			document.getElementById('remoteAgentVideoWrapper').style.display = "flex";
		}
		vid = remoteVideo;
	}else if(type === "remote-display") {
		document.getElementById('CentionChatRemoteDisplay').style.display = "inline-flex";
		document.getElementById('CentionChatClientScreenShareWrapper').style.display = "flex";
		vid = remoteDisplayVid;
		remoteDisplayStream = stream;

		var coBrowsingMode = store.getState().chat.socket.showCoBrowseFrame;
		if(coBrowsingMode) {
			store.dispatch(toggleVidChatFullScreen(true));
		}
	}
	if (vid) {
		vid.srcObject = stream;
		// TODO: Show loader.
		var playPromise = vid.play();

		if (playPromise !== undefined) {
			playPromise.then(_ => {
				// Automatic playback started!
				// Show playing UI.
				// stop video loader
				localVidLoading = false;
			})
			.catch(error => {
				// Auto-play was prevented
				// Show paused UI.
				console.log("Error on playing video:", error);
			});
		}
	}
}

async function onCreateOfferSuccess(desc, sessionId, type) {
	console.log(getName(), ' setLocalDescription start');
	try {
		if(desc.type == "offer") {
			await pc1.setLocalDescription(desc);
			onSetLocalSuccess(pc1);
			sendMessage(type, desc, sessionId, true);
		}
	} catch (e) {
		onSetSessionDescriptionError();
	}
}

//Client response/answer to agent's offer
export async function onCreateAnswerSuccess(desc, sessionId, init) {
	agentCalling = false;
	if(init) {
		store.dispatch(updateVideoInProgress(sessionId, true));
		store.dispatch(updateAgentUnAvailOnVidCall(true)); //set agent busy, perhaps need to rename this action
	} else {
		if (process.env.NODE_ENV !== 'production') {
			//console.log("DEBUG: Client create screen sharing answer success");
		}
		store.dispatch(handleAgentScreenShareOffer(false));
	}
	setRemoteDescription(desc, sessionId, init);
}

//Client response to agent's initiated co browse (client's offer)
export async function onOfferingAgentCoBrowse(desc, sessionId) {
	store.dispatch(handleClientOfferCoBrowsing(sessionId, true));
}

async function setRemoteDescription(desc, sessionId, init, coBrowse) {
	if (process.env.NODE_ENV !== 'production') {
		console.log(getName(),' setRemoteDescription start');
	}

	try {
		if(coBrowse) {
			await pc1.setRemoteDescription(desc);
				onSetRemoteSuccess(pc1, sessionId);
				if (process.env.NODE_ENV !== 'production') {
					console.log(getName(), "signalingState", pc1.signalingState);
				}
		} else {
			if(init) {
				await pc1.setRemoteDescription(desc);
				onSetRemoteSuccess(pc1, sessionId);
				if (process.env.NODE_ENV !== 'production') {
					console.log(getName(), "signalingState", pc1.signalingState);
				}
			} else {
				await pcDisplay.setRemoteDescription(desc);
				onSetRemoteDisplaySuccess(pcDisplay, sessionId);
				if (process.env.NODE_ENV !== 'production') {
					console.log(getName(), "signalingState", pcDisplay.signalingState);
				}
			}
		}
	} catch (e) {
		onSetSessionDescriptionError(e);
	}
}

function onSetLocalSuccess(pc) {
	console.log(`${getName(pc)} setLocalDescription complete`);
}

function onSetRemoteSuccess(pc, sessionId) {
	console.log(`${getName(pc)} setRemoteDescription complete - video call`);
	if(collectedICEs && collectedICEs.length > 0) {
		collectedICEs.forEach(function( cand, i ) {
			if(cand) {
				var candidate = new RTCIceCandidate(cand);
				pc.addIceCandidate(candidate)
				.then(() => {
					onAddIceCandidateSuccess(pc);
				})
				.catch((e) => {
					onAddIceCandidateError(pc, e);
				});
			}
		});
	}
}

function onSetRemoteDisplaySuccess(pc, sessionId) {
	console.log(`${getName(pc)} setRemoteDescription complete - screen sharing`);
	if(collectedDisplayICEs && collectedDisplayICEs.length > 0) {
		collectedDisplayICEs.forEach(function( cand, i ) {
			if(cand) {
				var candidate = new RTCIceCandidate(cand);
				pc.addIceCandidate(candidate)
				.then(() => {
					onAddIceCandidateSuccess(pc);
				})
				.catch((e) => {
					onAddIceCandidateError(pc, e);
				});
			}
		});
	}
}

function onCreateSessionDescriptionError(error) {
	console.log(`Failed to create session description: ${error.toString()}`);
}

function onSetSessionDescriptionError(error) {
	console.log(`Failed to set session description: ${error.toString()}`);
}

export async function handleICECandidate(pc, sessionId, e) {
	if(e.candidate) {
		sendMessage("ice-candidate", e.candidate, sessionId, false, agentCalling);
	}
}

//initiated by agent
//to add received ICE into connection
export async function handleReceiveICEFromClient(data) {
	if(data.clientScreenShare || data.agentScreenShare) {
		if(data.candidate) {
			try {
				//Only when receiving Ice candidate from client, then add to pc.addIceCandidate
				var candidate = new RTCIceCandidate(data.candidate);
				collectedDisplayICEs.push(data.candidate);
				if(pcDisplay) {
					await (pcDisplay.addIceCandidate(candidate));
					onAddIceCandidateSuccess(pcDisplay);
				}
			} catch (e) {
				if(pcDisplay) {
					onAddIceCandidateError(pcDisplay, e);
				}
			}
		}
	} else {
		if(data.candidate) {
			try {
				//Only when receiving Ice candidate from client, then add to pc.addIceCandidate
				var candidate = new RTCIceCandidate(data.candidate);
				collectedICEs.push(data.candidate);
				if(pc1) {
					await (pc1.addIceCandidate(candidate));
					onAddIceCandidateSuccess(pc1);
				}
			} catch (e) {
				if(pc1) {
					onAddIceCandidateError(pc1, e);
				}
			}
		}
	}
}

function onAddIceCandidateSuccess(pc) {
	console.log(`${getName(pc)} addIceCandidate success`);
}

function onIceStateChange(pc, sessionId, event) {
	console.log(`${getName(pc)} ICE state: ${pc.iceConnectionState}`);
	var clientScreenShareOfferInProgress = store.getState().chat.socket.clientScreenSharingOfferInProgress;
	var agentOfferInProgress = store.getState().chat.socket.agentScreenSharingOfferInProgress;
	if(clientScreenShareOfferInProgress) {
		store.dispatch(handleClientScreenShareOffer(false));
	} else if(agentOfferInProgress) {
		store.dispatch(handleAgentScreenShareOffer(false));
	} else {
		if(pc.iceConnectionState == 'connected') {
			if(pc.signalingState === "stable" && store.getState().chat.socket.onVideoCall) {
				if(remoteStream) {
					//start auto record here when both peer succesfully connected
					const automaticRecordingFeature = store.getState().server.features['chat.agent-video-call-auto-record'];
					if(automaticRecordingFeature) {
						let streams = {localStream: localStream, remoteStream: remoteStream};
						startRecording(sessionId, streams);
					}
				}
			}
		}
	}
}

function onICEGatheringChange(pc, event) {
	console.log(`${getName(pc)} ICE gathering state: ${pc.iceGatheringState}`);
}

function onAddIceCandidateError(pc, error) {
	console.log(`${getName(pc)} failed to add ICE Candidate: ${error.toString()}`);
}

function sendMessage(type, value, sessionId, init, agentInitiated, coBrowse) {
	if(type === "offer") {
		var payload = {
			sessionId: sessionId,
			type: "video-offer",
			sdp: value,
			init: init,
			presetBgImg: presetBgImg,
			presetBgBlur: presetBgBlur
		}
		AgentSocket.SendEvent("video-offer", payload);
	} else if(type === "answer") {
		AgentSocket.SendEvent('video-answer', {
			sessionId: sessionId,
			type: "video-answer",
			sdp: value,
			init: init,
			coBrowse: coBrowse
		});
		if(init) {
			store.dispatch(updateVideoInProgress(sessionId, true));
			//set agent unavailable to answer any other call
			store.dispatch(updateAgentUnAvailOnVidCall(true));
		}
	} else if(type === "ice-candidate") {
		var agentOfferInProgress = store.getState().chat.socket.agentScreenSharingOfferInProgress;
		var payload = {
			sessionId: sessionId,
			type: "new-ice-candidate",
			candidate: value,
			agentInitiated: agentInitiated,
			agentScreenShare: agentOfferInProgress
		}
		AgentSocket.SendEvent("new-ice-candidate", payload);
	} else if(type === "send-display-media") {
		var payload = {
			sessionId: sessionId,
			type: type,
			media: value
		}
		AgentSocket.SendEvent(type, payload);
	} else if(type === "request-display-media") {
		var payload = {
			sessionId: sessionId,
			type: type,
		}
		AgentSocket.SendEvent(type, payload);
	} else if(type === "stop-screen-share") {
		var payload = {
			sessionId: sessionId,
			type: "AGENT_STOP_SCREEN_SHARE"
		}
		AgentSocket.SendEvent("AGENT_STOP_SCREEN_SHARE", payload);
	} else if(type === "agent-stop-remote-screenshare") {
		AgentSocket.SendEvent("AGENT_STOP_REMOTE_SCREEN_SHARE", {
			sessionId: sessionId,
			type: "AGENT_STOP_REMOTE_SCREEN_SHARE",
			coBrowse: coBrowse
		});
	} else if(type === "init-cobrowse") {
		var payload = {
			sessionId: sessionId,
			type: "AGENT_INIT_CO_BROWSING",
		}
		AgentSocket.SendEvent("AGENT_INIT_CO_BROWSING", payload);
	}
}

// Handles hangup action: ends up call, closes connections and resets peers.
// fromClient - Client initiated the call
// bySystem - when chat ended but call were still in progress
export function hangUpCall(sessionId, clientHangup, bySystem) {
	const remoteVideo = document.getElementById("CentionChatVideoFrameRemoteAgent");
	if(remoteVideo) {
		if (remoteVideo.srcObject) {
			remoteVideo.srcObject.getTracks().forEach(track => track.stop());
		}
		remoteVideo.removeAttribute("src");
		remoteVideo.removeAttribute("srcObject");
	}

	disableWebCam();
	if(!clientHangup && !bySystem) {
		AgentSocket.SendEvent('hang-up', {
			sessionId: sessionId,
			type: "hang-up"
		});
	}
	store.dispatch(updateVideoInProgress(sessionId, false));
	closeVideoCall(true);
	agentCalling = false;
}

// Handle end everything that currently on
export function closeVideoFrames(sessionId) {
	if(store.getState().chat.socket.onVideoCall) {
		//hang up
		hangUpCall(sessionId, false, false)
	} else {
		//just stop camera
		if(localStream) {
			localStream.getVideoTracks()[0].stop();
			localStream.getAudioTracks()[0].stop();
		}
	}
	//close video frames
	document.getElementById('localAgentVideoWrapper').style.display = "none";
	document.getElementById('remoteAgentVideoWrapper').style.display = "none";

	if(blurActivated) {
		stopBlurBg();
	}
	if(changingBg){
		removeBg();
	}
	presetBgBlur = false;
	presetBgImg = "";

	var clientSharingScreen = store.getState().chat.socket.clientSharingScreen;
	var agentSharingScreen = store.getState().chat.socket.agentSharingScreen;
	if(clientSharingScreen) {
		handleStopClientScreenShare(sessionId, true);
		store.dispatch(handleClientOfferCoBrowsing(sessionId, false));
	}
	if(agentSharingScreen) {
		stopScreenShare(sessionId, false);
	}
	store.dispatch(showVideoCallFrame(sessionId, false, true));
	disconnectPC(true);
}

function hideVideoCallWrapper() {
	if(document.getElementById('localAgentVideoWrapper')) {
		document.getElementById('localAgentVideoWrapper').style.display = "none";
	}
	if(document.getElementById('remoteAgentVideoWrapper')) {
		document.getElementById('remoteAgentVideoWrapper').style.display = "none";
	}
}

// Handles end button action: end video
export function stopWebCam() {
	if(localStream) {
		localStream.getVideoTracks()[0].enabled = false;
	}
	console.log('Pausing video.');
}

//Close video call hence stop all necessary connection
export function closeVideoCall(hangup) {
	var remoteVideo = document.getElementById("CentionChatVideoFrameRemoteAgent");
	localVideo = document.getElementById("CentionChatVideoFrameLocalAgent");
	var remoteDisplayVideo = document.getElementById("client-display-view");
	var localDisplayVideo = document.getElementById("agent-display-view");
	if(hangup) {
		if (pc1) {
			pc1.ontrack = null;
			pc1.onremovetrack = null;
			pc1.onremovestream = null;
			pc1.onicecandidate = null;
			pc1.oniceconnectionstatechange = null;
			pc1.onsignalingstatechange = null;
			pc1.onicegatheringstatechange = null;
			pc1.onnegotiationneeded = null;

			if (remoteVideo && remoteVideo.srcObject) {
				remoteVideo.srcObject.getTracks().forEach(track => track.stop());
			}
			if (localVideo && localVideo.srcObject) {
				localVideo.srcObject.getTracks().forEach(track => track.stop());
			}
			remoteVideo.removeAttribute("src");
			remoteVideo.removeAttribute("srcObject");
			localVideo.removeAttribute("src");
			localVideo.removeAttribute("srcObject");

			try {
				localVideo.removeEventListener("loadedmetadata", onLocalVideoLoaded);
			} catch (error) {
				console.log(error);
			}
			disconnectPC();
		}
	} else {
		if(pcDisplay) {
			pcDisplay.ontrack = null;
			pcDisplay.onremovetrack = null;
			pcDisplay.onremovestream = null;
			pcDisplay.onicecandidate = null;
			pcDisplay.oniceconnectionstatechange = null;
			pcDisplay.onsignalingstatechange = null;
			pcDisplay.onicegatheringstatechange = null;
			pcDisplay.onnegotiationneeded = null;

			if (remoteDisplayVideo && remoteDisplayVideo.srcObject) {
				remoteDisplayVideo.srcObject.getTracks().forEach(track => track.stop());
			}
			if (localDisplayVideo && localDisplayVideo.srcObject) {
				localDisplayVideo.srcObject.getTracks().forEach(track => track.stop());
			}
			remoteDisplayVideo.removeAttribute("src");
			remoteDisplayVideo.removeAttribute("srcObject");
			localDisplayVideo.removeAttribute("src");
			localDisplayVideo.removeAttribute("srcObject");
			disconnectPC(true);
		}
	}

	//set agent back to available to answer other call
	store.dispatch(updateAgentUnAvailOnVidCall(false));
	hideVideoCallWrapper();
}

export function disableWebCam() {
	localVideo = document.getElementById("CentionChatVideoFrameLocalAgent");
	const remoteVideo = document.getElementById("CentionChatVideoFrameRemoteAgent");
	if(remoteVideo) {
		let localStream = localVideo.srcObject
		let remoteStream = remoteVideo.srcObject;
		if(localStream) {
			localStream.getVideoTracks().forEach(track => track.stop());
			localStream.getAudioTracks().forEach(track => track.stop());
		}
		if(remoteStream) {
			var remoteTracks = remoteStream.getTracks();
			remoteTracks.forEach(function(track) {
				if (track.enabled) {
					track.stop();
					track.enabled = false;
				}
			});
		}
	}
	console.log('Stopping media.');
}

/* disable/enable mic temporarily while session still ongoing */
export function muteVideo(muted) {
	localVideo = document.getElementById("CentionChatVideoFrameLocalAgent");
	if(muted) {
		if (localVideo.srcObject) {
			localVideo.srcObject.getAudioTracks().forEach(track => track.enabled = false);
		}
	} else {
		if (localVideo.srcObject) {
			localVideo.srcObject.getAudioTracks().forEach(track => track.enabled = true);
		}
	}
}

/* disable/enable camera temporarily while session still ongoing */
export function disableVideo(blinded) {
	localVideo = document.getElementById("CentionChatVideoFrameLocalAgent");
	if(blinded) {
		if (localVideo.srcObject) {
			localVideo.srcObject.getVideoTracks().forEach(track => track.enabled = false);
		}
	} else {
		if (localVideo.srcObject) {
			localVideo.srcObject.getVideoTracks().forEach(track => track.enabled = true);
		}
	}
}

export function stopVideoCamera(both) {
	localVideo = document.getElementById("CentionChatVideoFrameLocalAgent");
	if (localVideo.srcObject) {
		localVideo.srcObject.getTracks().forEach(track => track.stop());
	}
	if(both) {
		var remoteVideo = document.getElementById("CentionChatVideoFrameRemoteAgent");
		if (remoteVideo.srcObject) {
			remoteVideo.srcObject.getTracks().forEach(track => track.stop());
		}
	}
}

export function getLocalStream() {
	return localStream;
}

export function getRemoteStream() {
	return remoteStream;
}

export async function allowScreenShare(sessionId) {
	try {
		if (!displayMediaStream) {
			displayMediaStream = await navigator.mediaDevices.getDisplayMedia(displayMediaOptions);
		} else {
			console.log("Screen capture already requested..re request");
			displayMediaStream = "";
			displayMediaStream = await navigator.mediaDevices.getDisplayMedia(displayMediaOptions);
		}

		//Listen to click stop sharing from browser's UI
		displayMediaStream.getVideoTracks()[0].onended = function () {
			stopScreenShare(sessionId, false);
		};

		//show agent own video in self view
		var clientSharingScreen = store.getState().chat.socket.clientSharingScreen;
		var clientScreenShareOfferInProgress = store.getState().chat.socket.clientScreenSharingOfferInProgress;
		document.getElementById('CentionChatRemoteDisplay').style.display = "inline-flex";
		if(clientScreenShareOfferInProgress || clientSharingScreen) {
			document.getElementById('CentionChatClientScreenShareWrapper').style.display = "flex";
		} else {
			document.getElementById('CentionChatClientScreenShareWrapper').style.display = "none";
		}
		document.getElementById('CentionChatAgentScreenShareWrapper').style.display = "flex";
		document.getElementById('agent-display-view').srcObject = displayMediaStream;
		if(displayMediaStream) {
			displayMediaStream.getTracks().forEach(track => {
				if(track.kind === 'video') {
					if(pcDisplay.connectionState !== "closed") {
						store.dispatch(handleAgentScreenShareOffer(true));
						pcDisplay.addTrack(track, displayMediaStream);
					} else {
						console.log("Failed : PeerConnection status => ", pcDisplay.connectionState);
					}
				}
			});
		}

	} catch (e) {
		console.log('Unable to acquire screen capture: ' + e);
	}
}


//This function is allowing screen share without video call
export async function startScreenShare(sessionId) {
	//Have to initiate connection for both peers
	if(!pcDisplay || pcDisplay.connectionState === "closed") {
		//checked if current connection is closed/not
		//checking if there's some established connection on answer
		pcDisplay = createDisplayConnection(sessionId);
	} else {
		if (process.env.NODE_ENV !== 'production') {
			console.log("Already has established connection");
		}
	}

	//showVideoCallFrame
	store.dispatch(showVideoCallFrame(sessionId, true));
	//agent ss offer in progress
	allowScreenShare(sessionId);
}

export function stopScreenShare(sessionId, remote) {
	if(displayMediaStream) {
		let tracks = displayMediaStream.getTracks();
		tracks.forEach(track => track.stop());
		if(!remote) {
			sendMessage("stop-screen-share", "" , sessionId, false, "");
		}
	} else {
		if(remote) {
			//cancelled by client
			store.dispatch(showVideoCallFrame(sessionId, false));
		}
	}
	//agent stop screen share
	store.dispatch(handleAgentScreenShare(false));

	var clientSharingScreen = store.getState().chat.socket.clientSharingScreen;
	if(!clientSharingScreen) {
		disconnectPC(true);
	}
}

export function handleStopClientScreenShare(sessionId, remote, force) {
	const videoElem = document.getElementById('client-display-view');
	if(videoElem && videoElem.srcObject) {
		let tracks = videoElem.srcObject.getTracks();
		tracks.forEach(track => track.stop());
		videoElem.srcObject = null;
	}
	document.getElementById('CentionChatClientScreenShareWrapper').style.display = "none";
	//check if both no longer share
	var agentSharingScreen = store.getState().chat.socket.agentSharingScreen;
	var clientSharingScreen = store.getState().chat.socket.clientSharingScreen;
	var coBrowseSessionData = store.getState().chat.socket.showCoBrowseFrame;
	if(store.getState().app.errand.chat && store.getState().app.errand.chat.isOnCoBrowsing) {
		coBrowseSessionData = store.getState().app.errand.chat.isOnCoBrowsing;
	}
	if(!agentSharingScreen && !clientSharingScreen) {
		document.getElementById('CentionChatRemoteDisplay').style.display = "none";
	}
	if(remote) {
		//send signal to client to make indicate that agent stop his screen sharing
		sendMessage("agent-stop-remote-screenshare", "", sessionId, false, false, coBrowseSessionData);
	}
	store.dispatch(handleClientScreenShare(false));
	if(!agentSharingScreen && !store.getState().chat.socket.onVideoCall) {
		disconnectPC(true, sessionId, force);
	}
	if(coBrowseSessionData && !store.getState().chat.socket.onVideoCall) {
		store.dispatch(showVideoCallFrame(sessionId, false, true));
	}
	store.dispatch(handleClientOfferCoBrowsing(sessionId, false));
}

export function handleClientRejectScreenShare(sessionId) {
	stopScreenShare(sessionId, true);
	store.dispatch(handleAgentScreenShareOffer(false));
}


//Working on recording

let remoteMediaRecorder, localMediaRecorder;
let recordedRemoteBlobs, recordedLocalBlobs;
let timeStartRecord, timeEndRecord;

function isEmpty(obj) {
	for (var x in obj) { return false; }
	return true;
}

export function disconnectPC(screenShare, sessionId, force) {
	if(force) {
		if(pc1) {
			try{
				pc1.removeEventListener('icecandidate', handleICECandidate);
				pc1.removeEventListener('iceconnectionstatechange', onIceStateChange);
				pc1.removeEventListener('track', handleRemoteStreamAdded);
				pc1.removeEventListener('icegatheringstatechange', onIceGatheringStateChange);
				pc1.removeEventListener('datachannel', handleDataChannel);
			} catch(e) {
				console.log("Error while removing connection event listener");
			}
			pc1.close();
			pc1 = null;
			collectedICEs = [];
			if(blurActivated) {
				stopBlurBg();
			}
			if(changingBg){
				removeBg();
			}
			presetBgBlur = false;
			presetBgImg = "";
			console.log("Disconnected call connection");
		}
		if(pcDisplay) {
			try{
				pcDisplay.removeEventListener('icecandidate', handleICECandidate);
				pcDisplay.removeEventListener('iceconnectionstatechange', onIceStateChange);
				pcDisplay.removeEventListener('track', handleRemoteDisplayStreamAdded);
				pcDisplay.removeEventListener('icegatheringstatechange', onICEGatheringChange);
				pcDisplay.removeEventListener('datachannel', handleDisplayRemoteDataChannel);
			} catch(e) {
				console.log("Error while removing connection event listener");
			}
			pcDisplay.close();
			pcDisplay = null;
			collectedDisplayICEs = [];
			console.log("Disconnected screen sharing connection");
		}
		if(sendChannel){
			sendChannel.close();
		}
		if(receiveChannel){
			receiveChannel.close();
		}
		if(sessionId) {
			store.dispatch(showVideoCallFrame(sessionId, false, true));
			store.dispatch(handleClientOfferCoBrowsing(sessionId, false));
		}
	} else {
		//don't disconnect if co browsing still happens
		var coBrowsingMode = store.getState().chat.socket.showCoBrowseFrame;
		if(!coBrowsingMode) {
			if(screenShare) {
				if(pcDisplay) {
					pcDisplay.close();
					pcDisplay = null;
					collectedDisplayICEs = [];
					console.log("Disconnected screen sharing connection");
				}
			} else {
				if(pc1) {
					pc1.close();
					pc1 = null;
					collectedICEs = [];
					if(sendChannel){
						sendChannel.close();
					}
					if(receiveChannel){
						receiveChannel.close();
					}
					if(blurActivated) {
						stopBlurBg();
					}
					if(changingBg){
						removeBg();
					}
					presetBgBlur = false;
					presetBgImg = "";
					console.log("Disconnected call connection");
				}
			}
		} else {
			console.log("Co browsing still on going");
			//todo @sue, should warn browser , this happens when agent to switch to other chat/errand.
		}
	}
}

export function startRecording(sessionId, streams) {
	if(!isEmpty(streams)) {
		localStream = streams.localStream;
		remoteStream = streams.remoteStream;
	}
	timeStartRecord = window.performance.now();
	recordedRemoteBlobs = [], recordedLocalBlobs = [];
	let options = {mimeType: 'video/webm;codecs=vp9,opus'};
	if (!MediaRecorder.isTypeSupported(options.mimeType)) {
		console.error(`${options.mimeType} is not supported`);
		options = {mimeType: 'video/webm;codecs=vp8,opus'};
		if (!MediaRecorder.isTypeSupported(options.mimeType)) {
			console.error(`${options.mimeType} is not supported`);
			options = {mimeType: 'video/webm'};
			if (!MediaRecorder.isTypeSupported(options.mimeType)) {
				console.error(`${options.mimeType} is not supported`);
				options = {mimeType: ''};
			}
		}
	}

	try {
		if(remoteStream && localStream) {
			remoteMediaRecorder = new MediaRecorder(remoteStream, options);
			localMediaRecorder = new MediaRecorder(localStream, options);
		} else {
			console.log("No streams found");
			return;
		}
	} catch (e) {
		console.error('Exception while creating MediaRecorder:', e);
		return;
	}

	if(remoteMediaRecorder) {
		remoteMediaRecorder.onstop = (event) => {
			//console.log('Recorder for remote stopped: ', event);
		};

		remoteMediaRecorder.addEventListener('dataavailable', e => handleDataAvailable(sessionId, "remote",  e));
		remoteMediaRecorder.start();
		AgentSocket.SendEvent('CHAT_VIDEO_RECORD_START', {
			sessionId: sessionId,
			isAgent: false
		});
	}
	if(localMediaRecorder) {
		localMediaRecorder.onstop = (event) => {
			//console.log('Recorder for local stopped: ', event);
		};

		localMediaRecorder.addEventListener('dataavailable', e => handleDataAvailable(sessionId, "local", e));
		localMediaRecorder.start();
		AgentSocket.SendEvent('CHAT_VIDEO_RECORD_START', {
			sessionId: sessionId,
			isAgent: true
		});
	}
	store.dispatch(handleAgentRecordVid(sessionId, true));
}

export function stopRecording(sessionId) {
	timeEndRecord = window.performance.now();
	let recording = store.getState().chat.socket.recording;
	if(recording) {
		if(localMediaRecorder && localMediaRecorder.state !== "inactive") {
			try{
				localMediaRecorder.removeEventListener('dataavailable',handleDataAvailable);
			} catch(e) {
				console.log("Error while removing local recorder event listener");
			}
			localMediaRecorder.stop();
		}
		if(remoteMediaRecorder && remoteMediaRecorder.state !== "inactive") {
			try{
				remoteMediaRecorder.removeEventListener('dataavailable',handleDataAvailable);
			} catch(e) {
				console.log("Error while removing remote recorder event listener");
			}
			remoteMediaRecorder.stop();
		}
		let timeRecorded = (timeEndRecord - timeStartRecord) / 1000;
		AgentSocket.SendEvent('CHAT_VIDEO_RECORD_STOP', {
			sessionId: sessionId,
			duration: timeRecorded
		});
	}
}

function handleDataAvailable(sessionId, type, event) {
	let timeRecorded = (timeEndRecord - timeStartRecord) / 1000;
	if(type == "remote") {
		recordedRemoteBlobs.push(event.data);
		store.dispatch(handleSavingRecordedVidChat(sessionId, type , recordedRemoteBlobs, timeRecorded));
	}else {
		recordedLocalBlobs.push(event.data);
		store.dispatch(handleSavingRecordedVidChat(sessionId, type , recordedLocalBlobs, timeRecorded));
	}
}

//error handlings

export function handleCheckInvalidVideoError(err) {
	if(pc1.connectionState === "connecting") {
		alert("Error : "+err.data["msg"]);
		store.dispatch(showVideoCallFrame(err.sessionId, false));
		console.log("Unused video call frame closed");
		disableWebCam();
		disconnectPC();

		//set agent available again
		store.dispatch(updateVideoInProgress(err.sessionId, false));
		store.dispatch(updateAgentUnAvailOnVidCall(false));
	} else {
		if(pc1.connectionState === "connected"){
			console.log("Video call error somewhere not affect this call");
		} else {
			alert("Error : "+err.data["msg"]);
		}
	}
}

function reportError(errMessage) {
	log_error(`Error ${errMessage.name}: ${errMessage.message}`);
}

function log_error(text) {
	var time = new Date();
	console.trace("[" + time.toLocaleTimeString() + "] " + text);
}

function errorCallBack(err) {
	console.log("errorCallBack => ", err);
}

export function getLocalVidLoadingStatus() {
	return localVidLoading;
}

function sendMsgThruChannel(msg) {
	if(sendChannel && sendChannel.readyState === "open"){
		sendChannel.send(JSON.stringify(msg));
	} else if(receiveChannel && receiveChannel.readyState === "open"){
		receiveChannel.send(JSON.stringify(msg));
	} else {
		console.log("No opened data channel");
	}
}

function dataChannelsOpened(){
	if((sendChannel && sendChannel.readyState === "open") || (receiveChannel && receiveChannel.readyState === "open")){
		return true;
	}
	return false;
}

function checkWebGL2() {
	const gl = document.createElement('canvas').getContext('webgl2');
	const setBgErrorMgsg = I("This browser doesn't support background effects.");
	const setBgErrorMgsg_details = I("Background effects use web technologies that your browser doesn't support. Please use supported browser.");
	const dismissBtn = DISMISS_BUTTONS;
	if (!gl) {
		if (typeof WebGL2RenderingContext !== 'undefined') {
			console.log('your browser appears to support WebGL2 but it might be disabled. Try updating your OS and/or video card drivers');
			store.dispatch(customConfirm(
				[setBgErrorMgsg, setBgErrorMgsg_details]
				, dismissBtn,
			));
			return false;
		} else {
			console.log('your browser has no WebGL2 support at all');
			store.dispatch(customConfirm(
				[setBgErrorMgsg, setBgErrorMgsg_details]
				, dismissBtn,
			));
			return false;
		}
	} else {
		return true;
	}
}

export async function handleBlurBg(id) {
	const canvasElement = document.getElementById('agentVidCanvas')
	localVideo = document.getElementById('CentionChatVideoFrameLocalAgent');

	if(checkWebGL2()){
		const canvasCtx = canvasElement.getContext('2d');
		const selfieSegmentation = new SelfieSegmentation({locateFile: (file) => {
			return `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}`;
		}});
		selfieSegmentation.setOptions({
			modelSelection: 1,
		});
		selfieSegmentation.onResults(onResultsForBlur);

		if ('requestVideoFrameCallback' in HTMLVideoElement.prototype) {
			blurActivated = true;
			async function localOnFrame(now, metadata) {
				localVideo.currentTime = now;
				await selfieSegmentation.send({image: localVideo});
				if(blurActivated){
					localVideo.requestVideoFrameCallback(localOnFrame);
				}
			}
			localVideo.requestVideoFrameCallback(localOnFrame);
			localVideo.hidden = true;
			canvasElement.hidden = false;
			if(changingBg){
				removeBg(id);
			}
			if(remoteStream && store.getState().chat.socket.onVideoCall){
				let msg = {
					"sessionId": id,
					"type": "video-blur-start"
				}
				if(dataChannelsOpened()){
					sendMsgThruChannel(msg);
				}else {
					var payload = {
						sessionId: id,
						type: "video-blur-start",
					}
					AgentSocket.SendEvent("video-blur-start", payload);
				}
			} else {
				presetBgBlur = true;
			}
		} else {
			console.log('requestVideoFrameCallback API not supported');
			const setBgErrorMgsg = I("This browser doesn't support background effects.");
			const dismissBtn = DISMISS_BUTTONS;
			store.dispatch(customConfirm(
				[setBgErrorMgsg]
				, dismissBtn,
			));

			localVideo.hidden = false;
			canvas.hidden = true;
		}

		function onResultsForBlur(results) {
			canvasCtx.save();
			canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
			canvasCtx.drawImage(results.segmentationMask, 0, 0, canvasElement.width, canvasElement.height);

			//re-draw selfie inner segment
			canvasCtx.globalCompositeOperation = 'source-in';
			var imageData = results.image;
			var pixel = imageData;
			for (var p = 0; p < pixel.length; p += 4) {
				if (results.segmentationMask.data[p / 4] == 0) {
					pixel[p + 3] = 0;
				}
			}
			canvasCtx.imageSmoothingEnabled = true;
			canvasCtx.drawImage(imageData, 0, 0, canvasElement.width, canvasElement.height);

			// Only overwrite missing pixels with blur effect
			canvasCtx.globalCompositeOperation = 'destination-atop';
			canvasCtx.filter = 'blur(4px)';
			canvasCtx.drawImage(results.image, 0, 0, canvasElement.width, canvasElement.height);
			canvasCtx.restore();
		}
	} else {
		localVideo.hidden = false;
		canvas.hidden = true;
	}
}

export function stopBlurBg(id) {
	const canvas = document.getElementById('agentVidCanvas');
	blurActivated = false;
	canvas.hidden = true;
	if(!changingBg){
		localVideo.hidden = false;
	}
	if(id){
		if(remoteStream && store.getState().chat.socket.onVideoCall){
			let msg = {
				"sessionId": id,
				"type": "video-blur-stop"
			}
			if(dataChannelsOpened()){
				sendMsgThruChannel(msg);
			}else {
				var payload = {
					sessionId: id,
					type: "video-blur-stop",
				}
				AgentSocket.SendEvent("video-blur-stop", payload);
			}
		} else {
			presetBgBlur = false;
		}
	}
}

let changingBg = false;

export async function changeBg(id, img) {
	const canvasBgAgent = document.getElementById("canvasBgAgent");
	const bgContainer = document.getElementById('bg-container');

	if(checkWebGL2()){
		resizeElement();
		localVideo = document.getElementById('CentionChatVideoFrameLocalAgent');
		const canvasElement = document.getElementById('canvasBgAgent');
		const canvasCtx = canvasElement.getContext('2d');

		const selfieSegmentation = new SelfieSegmentation({locateFile: (file) => {
			return `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}`;
		}});
		selfieSegmentation.setOptions({
			modelSelection: 1,
		});
		selfieSegmentation.onResults(onResults);
		let extractingDone = false;
		store.dispatch(updateVideoExtractingStatus(false));
		sendChangeBgProgressSignal(id, false);
		if ('requestVideoFrameCallback' in HTMLVideoElement.prototype) {
			changingBg = true;
			async function localOnFrame(now, metadata) {
				localVideo.currentTime = now;
				await selfieSegmentation.send({image: localVideo});
				if(changingBg){
					localVideo.requestVideoFrameCallback(localOnFrame);
					if(!extractingDone) {
						extractingDone = true;
						store.dispatch(updateVideoExtractingStatus(true));
						sendChangeBgProgressSignal(id, true);
					}
				}
			}
			localVideo.requestVideoFrameCallback(localOnFrame);

			localVideo.hidden = true;
			bgContainer.hidden = false;

			if(blurActivated) {
				stopBlurBg(id);
			}
			if(remoteStream && store.getState().chat.socket.onVideoCall){
				let msg = {
					"sessionId": id,
					"type": "video-bg-start",
					"img": img
				}
				if(dataChannelsOpened()){
					sendMsgThruChannel(msg);
				}else {
					var payload = {
						sessionId: id,
						type: "video-bg-start",
						img: img
					}
					AgentSocket.SendEvent("video-bg-start", payload);
				}
			} else {
				presetBg = true;
				presetBgImg = img;
			}

		} else {
			console.log('requestVideoFrameCallback API not supported');
			const setBgErrorMgsg = I("This browser doesn't support background effects.");
			const dismissBtn = DISMISS_BUTTONS;
			store.dispatch(customConfirm(
				[setBgErrorMgsg]
				, dismissBtn,
			));

			const vidBgContainer = document.getElementById('vid-bg-container');
			bgContainer.hidden = true;
			canvasBgAgent.hidden = true;
			vidBgContainer.hidden = true;
			localVideo.hidden = false;
		}

		function onResults(results) {
			canvasCtx.save();
			canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
			canvasCtx.drawImage(results.segmentationMask, 0, 0, canvasElement.width, canvasElement.height);
			canvasCtx.globalCompositeOperation = 'source-in';
			var imageData = results.image;
			var pixel = imageData;
			for (var p = 0; p < pixel.length; p += 4) {
				if (results.segmentationMask.data[p / 4] == 0) {
					pixel[p + 3] = 0;
				}
			}
			canvasCtx.imageSmoothingEnabled = true;
			canvasCtx.drawImage(imageData, 0, 0, canvasElement.width, canvasElement.height);
			canvasCtx.restore();
		}
	} else {
		const vidBgContainer = document.getElementById('vid-bg-container');
		bgContainer.hidden = true;
		canvasBgAgent.hidden = true;
		vidBgContainer.hidden = true;
		localVideo.hidden = false;
	}
}

function sendChangeBgProgressSignal(id, status) {
	const msg = {
		"sessionId": id,
		"type": "video-bg-change-progress",
		"status": status
	}
	if(dataChannelsOpened()){
		sendMsgThruChannel(msg);
	}else {
		const payload = {
			sessionId: id,
			type: "video-bg-change-progress",
			status: status
		}
		AgentSocket.SendEvent("video-bg-change-progress", payload);
	}
}

export function sendChangeBgOnlySignal(sessionId, img) {
	if(remoteStream && store.getState().chat.socket.onVideoCall){
		let msg = {
			"sessionId": sessionId,
			"type": "video-bg-change",
			"img": img
		}
		if(dataChannelsOpened()){
			sendMsgThruChannel(msg);
		}else {
			var payload = {
				sessionId: sessionId,
				type: "video-bg-change",
				img: img
			}
			AgentSocket.SendEvent("video-bg-change", payload);
		}
	}
}

export function removeBg(id) {
	changingBg = false;
	const canvasBgAgent = document.getElementById("canvasBgAgent");
	const bgContainer = document.getElementById('bg-container');
	const vidBgContainer = document.getElementById('vid-bg-container');

	bgContainer.hidden = true;
	canvasBgAgent.hidden = true;
	vidBgContainer.hidden = true;
	if(!blurActivated) {
		localVideo.hidden = false;
	}
	if(id){
		if(remoteStream && store.getState().chat.socket.onVideoCall){
			let msg = {
				"sessionId": id,
				"type": "video-bg-stop"
			}
			if(dataChannelsOpened()){
				sendMsgThruChannel(msg);
			}else {
				var payload = {
					sessionId: id,
					type: "video-bg-stop",
				}
				AgentSocket.SendEvent("video-bg-stop", payload);
			}
		} else {
			presetBg = false;
			presetBgImg = "";
		}
	}
}

function resizeElement() {
	const canvasBgAgent = document.getElementById("canvasBgAgent");
	const bgContainer = document.getElementById('bg-container');
	const vidBgContainer = document.getElementById('vid-bg-container');

	bgContainer.hidden = false;
	canvasBgAgent.hidden = false;
	vidBgContainer.hidden = false;

	bgContainer.height = localVideo.videoHeight;
	bgContainer.width = localVideo.videoWidth;
	vidBgContainer.height = localVideo.videoHeight;
	vidBgContainer.width = localVideo.videoWidth;
	localVideo.hidden = true;
}

//co browsing
export function initCoBrowse(sessionId) {
	sendMessage("init-cobrowse", "", sessionId, true);
}

export function getClientSnapShots() {
	return clientSnapShots;
}

export function sendMouseMove(sid, type, data) {
	let msg = {};
	msg.sessionId = sid;
	msg.type = type;
	if(type === "mouse-move" || type === "mouse-down" || type === "mouse-click" || type === "keydown" || type === "init-highlight" || type === "start-highlight") {
		msg.data = data;
	}
	sendMsgThruChannel(msg);
}

export function sendScrollDirection(sid, type) {
	let msg = {};
	msg.sessionId = sid;
	msg.type = type;
	sendMsgThruChannel(msg);
}