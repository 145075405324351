import {
	evtNOTIFICATION_MESSAGE_DISMISS,
	evtNOTIFICATION_MESSAGE_READ,
	evtNOTIFICATION_MESSAGE_UNREAD,
} from '../../redux/constants/constants';
import AgentSocket from './agentsocket';

export const dismissNotification = (id, announcement) => {
	if (cflag.IsActive("2023-09-20.CEN-109.announcement") ) {
		AgentSocket.SendEvent(evtNOTIFICATION_MESSAGE_DISMISS, {
			id: id,
			announcement: announcement
		}, (ack) => {
			if (ack.error) {
				alert(ack.error);
			}
		});
	} else {
		AgentSocket.SendEvent(evtNOTIFICATION_MESSAGE_DISMISS, {
			id: id
		}, (ack) => {
			if (ack.error) {
				alert(ack.error);
			}
		});
	}
}

export const dismissMultiNotification = (ids) => {
	AgentSocket.SendEvent(evtNOTIFICATION_MESSAGE_DISMISS, {
		id: 0,
		ids: ids
	}, (ack) => {
		if (ack.error) {
			alert(ack.error);
		}
	});
}

export const readNotification = (id, announcement) => {
	if (cflag.IsActive("2023-09-20.CEN-109.announcement") ) {
		AgentSocket.SendEvent(evtNOTIFICATION_MESSAGE_READ, {
			id: id,
			announcement: announcement
		}, (ack) => {
			if (ack.error) {
				alert(ack.error);
			}
		});
	} else {
		AgentSocket.SendEvent(evtNOTIFICATION_MESSAGE_READ, {
			id: id
		}, (ack) => {
			if (ack.error) {
				alert(ack.error);
			}
		});
	}
}

export const readMultiNotification = (ids) => {
	AgentSocket.SendEvent(evtNOTIFICATION_MESSAGE_READ, {
		id: 0,
		ids: ids
	}, (ack) => {
		if (ack.error) {
			alert(ack.error);
		}
	});
}

export const unreadNotification = (id, announcement) => {
	if (cflag.IsActive("2023-09-20.CEN-109.announcement") ) {
		AgentSocket.SendEvent(evtNOTIFICATION_MESSAGE_UNREAD, {
			id: id,
			announcement: announcement
		}, (ack) => {
			if (ack.error) {
				alert(ack.error);
			}
		});
	} else {
		AgentSocket.SendEvent(evtNOTIFICATION_MESSAGE_UNREAD, {
			id: id
		}, (ack) => {
			if (ack.error) {
				alert(ack.error);
			}
		});
	}
}

export const unreadMultiNotification = (ids) => {
	AgentSocket.SendEvent(evtNOTIFICATION_MESSAGE_UNREAD, {
		id: 0,
		ids: ids
	}, (ack) => {
		if (ack.error) {
			alert(ack.error);
		}
	});
}
