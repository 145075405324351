// constants for call domain that can be used accross most folders regardless
// separation concern. PLEASE ARRANGE IN ALPHANUMERIC WHENEVER POSSIBLE.
import { I } from './config';
import {
	POSTPONED,
	REVIEW
	, REVIEW_ERRAND
} from '../path';

// i18n texts ------------------------------------------------------------------
export const TXT_EXIT_REVIEW = I("refreshing errand list")
	, TXT_INVALID_CIPHER_KEY = I("errand {ERRAND_ID} has invalid cipher key")
	, TXT_ERRAND_NOT_REVIEW = I("Errand is not under review.")
	, TXT_ERRAND_PENDING_REVIEW = I("Errand pending for review.")
	, TXT_OPEN_REVIEW = I("opening review folder")
	;
export const REVIEW_ROUTES = [REVIEW, REVIEW_ERRAND]
	;
export const ROUTE_MATCHER = {exact: true, path: REVIEW_ROUTES}
