import {
    getExtQueueType
} from './helpers';

const LIMITEDWFDB_NAME = "limitedWFSettings";
const AGENTDATADB_NAME = "agentData";
const ERRANDEXPDB_NAME = "errandExpiry";
const INDEXEDDB_VER = 1;
const limitedSettings = 'limitedSettings';
const agentData = 'agentData';
const errandExpireData = 'errandExpireData';
const expiryTime = 3600 * 4

export const storeWfLimitedSettingsValues = (value) =>{
	var request = window.indexedDB.open(LIMITEDWFDB_NAME, INDEXEDDB_VER);
	request.onerror = function(event){
		console.info(request.error);
	};
	request.onsuccess = function(event){
		var db = event.target.result;
		var transaction = db.transaction(limitedSettings, 'readwrite');
		transaction.onsuccess = function(event) {
		};
		transaction.oncomplete = function() {
			db.close();
		};
		var store = transaction.objectStore(limitedSettings);
		store.add(value);
	};
	request.onupgradeneeded = function(event){
		var db = event.target.result;
		var newStore = db.createObjectStore(limitedSettings,
			{keyPath: "activeUserId"});
	}
}

function removeIndexedDb(id, store){
	return new Promise(function(resolve, reject){
		var request = store.delete(id);
		request.onsuccess = function(event){
			console.info("removed entry");
			resolve(true);
		}
		request.onerror = function(event){
			resolve(true);
		}
	});
}

export const getLocalWfLimtedSettings = (id) => {
	return new Promise(function(resolve, reject){
		var db;
		if(getExtQueueType() === ""){
			resolve(null);
		}
		let openRequest = window.indexedDB.open(LIMITEDWFDB_NAME, INDEXEDDB_VER);
		openRequest.onerror = function(event){
			console.log(openRequest.error);
			resolve(null);
		}
		openRequest.onupgradeneeded = function(event){
			var db = event.target.result;
			var newStore = db.createObjectStore(limitedSettings,
				{keyPath: "activeUserId"});
		}
		openRequest.onsuccess = function(event){
			db = openRequest.result;
			var transaction = db.transaction(limitedSettings, 'readwrite');
			var store = transaction.objectStore(limitedSettings);
			var request = store.get(id);

			transaction.onerror = function() {
				resolve(null);
			}

			transaction.oncomplete = function() {
				db.close();
			};

			request.onerror = function(event){
				console.info(request.error);
				resolve(null);
			}
			request.onsuccess = function(event){
				if(typeof request.result == 'undefined'){
					resolve(null);
					return;
				}
				let settings = request.result;
				if(settings.settingExpiry < Math.floor(Date.now() / 1000)){
					removeIndexedDb(id, store).then(() => {
						resolve(null);
					});
				}
				resolve(request.result);
			}
		}
	})
}

export const storeAgentData = (id, state) =>{
	var request = window.indexedDB.open(AGENTDATADB_NAME, INDEXEDDB_VER);
	request.onerror = function(event){
		console.info(request.error);
	}
	request.onupgradeneeded = function(event){
		var db = event.target.result;
		var newStore = db.createObjectStore(agentData,
			{keyPath: "activeUserId"});
	}
	request.onsuccess = function(event){
		var db = event.target.result;
		var transaction = db.transaction(agentData, 'readwrite');
		transaction.onsuccess = function(event) {
		};
		transaction.oncomplete = function() {
			db.close();
		};
		var store = transaction.objectStore(agentData);
		let dataValue  = {
			agentAreas: state.app.workflow.agentAreas
			, agents: state.app.workflow.agents
			, channels: state.app.workflow.channels
			, adminTagList: state.app.workflow.adminTagList
			, agentGroups: state.app.workflow.agentGroups
			, knowledgeBaseList: state.app.workflow.knowledgeBaseList
			, expiry: Math.floor(Date.now() / 1000) + expiryTime
		}
		var entry = {'activeUserId': id, "agentData": dataValue};
		store.add(entry);
	}
}

export const getAgentData = (id) => dispatch =>{
	return new Promise(function(resolve, reject){
		var db;
		let openRequest = window.indexedDB.open(AGENTDATADB_NAME,
			INDEXEDDB_VER);
		openRequest.onerror = function(event){
			console.log(openRequest.error);
			resolve(null);
		}
		openRequest.onupgradeneeded = function(event){
			var db = event.target.result;
			var newStore = db.createObjectStore(agentData,
				{keyPath: "activeUserId"});
		}
		openRequest.onsuccess = function(event){
			db = openRequest.result;
			var transaction = db.transaction(agentData, 'readwrite');
			var store = transaction.objectStore(agentData);
			var request = store.get(id);

			transaction.onerror = function() {
				console.info(request.error);
				resolve(null);
			}

			transaction.oncomplete = function() {
				db.close();
			};

			request.onerror = function(event){
				console.info(request.error);
				resolve(null);
			}
			request.onsuccess = function(event){
				if(typeof request.result == 'undefined'){
					resolve(null);
					return;
				}
				let data = request.result.agentData;
				if(data.expiry < Math.floor(Date.now() / 1000)){
					removeIndexedDb(id, store).then(() => {
						resolve(null);
					});
				}
				resolve(data);
			}
		}
	})
}

export const storeErrandClose = (eid, endTime) =>{
    var request = window.indexedDB.open(ERRANDEXPDB_NAME, INDEXEDDB_VER);
    request.onerror = function(event){
        console.info(request.error);
    };
    request.onsuccess = function(event){
        var db = event.target.result;
        var transaction = db.transaction(errandExpireData, 'readwrite');
        transaction.onsuccess = function(event) {
        };
        transaction.oncomplete = function() {
            db.close();
        };
        var store = transaction.objectStore(errandExpireData);
		let dataValue  = {
			endTime: endTime
		}
		var entry = {'errandId': eid, "value": dataValue};
		store.add(entry);
    };
    request.onupgradeneeded = function(event){
        var db = event.target.result;
        var newStore = db.createObjectStore(errandExpireData,
            {keyPath: "errandId"});
    }
}

export const getErrandCloseTimer = (id) => dispatch => {
    return new Promise(function(resolve, reject){
		var db;
		let openRequest = window.indexedDB.open(ERRANDEXPDB_NAME,
			INDEXEDDB_VER);
		openRequest.onerror = function(event){
			console.log(openRequest.error);
			return null;
		}
		openRequest.onupgradeneeded = function(event){
			var db = event.target.result;
			var newStore = db.createObjectStore(errandExpireData,
				{keyPath: "errandId"});
		}
		openRequest.onsuccess = function(event){
			db = openRequest.result;
			var transaction = db.transaction(errandExpireData,
				'readwrite');
			var store = transaction.objectStore(errandExpireData);
			var request = store.get(id);

			console.info(id);
			transaction.onerror = function() {
				console.info(request.error);
				return null;
			}

			transaction.oncomplete = function() {
				db.close();
			};

			request.onerror = function(event){
				console.info(request.error);
				return null;
			}
			request.onsuccess = function(event){
				if(typeof request.result == 'undefined'){
					return null;
				}
				let data = request.result.value;
					console.info(data);
				return data;
			}
		}
	})
}
