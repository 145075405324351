import { createMuiTheme } from '@material-ui/core/styles'
import each from 'lodash/each'
import endsWith from 'lodash/endsWith'
import trimEnd from 'lodash/trimEnd'
// TODO: probably wanna fix this _variables path to bring it common folder to
// have a more proper dependencies folder tree as any module within common
// folder should not import any module within styles folder.
import {
  screenSmMin,
  screenMdMin,
  screenLgMin,
  screenXlMin
} from '../styles/_variables'

const allSuffixes = [
  'px'
]

const removeUnitIfExist = s => {
  each(allSuffixes, v => {
    if (endsWith(s, v)) {
      s = trimEnd(s, v)
      return true
    }
  })
  return parseInt(s, 10)
}

export const screenSmMinNumber = removeUnitIfExist(screenSmMin)
export const screenMdMinNumber = removeUnitIfExist(screenMdMin)
export const screenLgMinNumber = removeUnitIfExist(screenLgMin)
export const screenXlMinNumber = removeUnitIfExist(screenXlMin)
export const breakpoints = {
  xs: 0,
  sm: screenSmMinNumber,
  md: screenMdMinNumber,
  lg: screenLgMinNumber,
  xl: screenXlMinNumber
}

// TODO: Change default theme here.
const theme = createMuiTheme({ breakpoints: { values: breakpoints } })

export const { keys: breakpointKeys } = theme.breakpoints

export default theme

export const fillupMissingBreakpointKeys = (
  lastDefault,
  objectByBreakpointKeys
) => {
  const result = {}
  each(breakpointKeys, key => {
    let value = objectByBreakpointKeys[key]
    if (typeof value === 'undefined') {
      value = lastDefault
    } else {
      lastDefault = value
    }
    result[key] = value
  })
  return result
}
